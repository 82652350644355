* {
  font-family: "Arya", sans-serif;
}

body {
  margin: 0;
  font-family: "Arya", -apple-system, BlinkMacSystemFont, "Arya", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: white;
}

a:link {
  color: inherit;
}

button {
  color: black;
}

h2 {
  font-size: xx-large;
  color: #2aabe1;
}

h5 {
  font-size: xx-large;
  font-weight: 800;
  color: black;
}

span {
  color: black;
}

.home-box {
  background-color: #2aabe1;
  border: solid 1px white;
  color: white;
}

.four-box {
  background-color: #2aabe1;
  color: white;
  min-height: 200px;
}

.overlay-container {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  transition: 0.5s ease;
  background-color: #2aabe1;
}

.overlay-container:hover .overlay {
  opacity: 0;
}

.overlay-container:hover .overlay-text {
  opacity: 1;
  color: #2aabe1;
  font-size: larger;
  margin: -10px;
  font-weight: bolder;
}

.overlay-container:hover .text {
  opacity: 1;
  font-size: 280%;
  font-weight: bolder;
}

.text {
  color: white;
  font-size: 240%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.5s ease;
  opacity: 1;
}

.overlay-text {
  color: white;
  font-size: 100%;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  margin: -5px;
  padding: 0px;
  transition: 0.5s ease;
  opacity: 1;
}

.caro-text {
  font-size: x-large;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.caro-header {
  font-size: xx-large;
  font-weight: 900;
}

.text-emin {
  color: #2aabe1;
}

#app {
  height: 100%;
}
html,
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
